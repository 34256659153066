import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Property, PropertyAttributes } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getPagination = (state: State) => state.pagination;

export const selectState = createFeatureSelector<State>('properties');

export const selectAllEntities =
  featureAdapter.getSelectors(selectState).selectEntities;

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectById = (id: number) =>
  createSelector(
    selectAllItems,
    (items: Property[]) => items && items.find((p) => +p.id === +id),
  );

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectPagination = createSelector(selectState, getPagination);

export const selectPropertyIdSelected = createSelector(
  selectState,
  (state: State) => state.propertyIdSelected,
);

export const selectPropertySelected = createSelector(
  selectAllEntities,
  selectPropertyIdSelected,
  (entities, id) => {
    return entities[id];
  },
);

export const selectPropertyAttributes = createSelector(
  selectPropertySelected,
  (propertySelected) => propertySelected && propertySelected.attributes,
);

export const selectPropertyAttributeByName = (
  attribute: keyof PropertyAttributes,
) =>
  createSelector(
    selectPropertyAttributes,
    (attributes) => attributes?.[attribute],
  );
